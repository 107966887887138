<template>
    <v-card>
        <v-card-title primary-title>
            <v-icon left large v-if="noleidoscount > 0">mdi-mailbox-up-outline</v-icon>
            <v-icon left large v-else>mdi-mailbox-outline</v-icon>
            <span class="headline">Inbox</span>
            <v-spacer></v-spacer>
            <v-progress-circular v-if="dialogEspera"
            indeterminate
            color="primary"
            ></v-progress-circular>

        </v-card-title>
        <v-divider></v-divider>

        
        <v-card-text>
            <span v-if="noleidoscount == 0">No tienes ningún mensaje nuevo.</span>
            <v-list two-line>
                <template v-for="(item, index) in mensajes">
                    <v-list-item :key="item.idpedido" @click="itemSelected(item)">
                        <v-list-item-content>
                            
                            <v-list-item-title :class="!item.leido ? `red--text title`: '' ">#{{ item.idpedido }} {{ item.pedido.cliente_nombre }}</v-list-item-title>
                            <v-list-item-subtitle v-text="item.mensaje" class="text--primary"></v-list-item-subtitle>
                            <v-list-item-subtitle v-text="item.fecha"></v-list-item-subtitle>
                            
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-icon>mdi-magnify-plus-outline</v-icon>
                        </v-list-item-action>
                    </v-list-item>

                    <v-divider
                        v-if="index + 1 < mensajes.length"
                        :key="item.id"
                    ></v-divider>
                </template>
            </v-list>
        </v-card-text>
        <SnackbarMensajes v-bind:sb="sb"></SnackbarMensajes>


        <v-dialog v-model="dialogMensaje" scrollable max-width="800px">
            
            <v-card color="grey lighten-5">
                <v-card-title>
                    <span class="title">{{ mensaje.fecha }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <span class="title red--text">{{ mensaje.mensaje }}</span>
                    <p/>
                    
                    <v-row>
                        <v-col cols="12" md="5" class="pt-0">
                            <span class="body-2">{{ resumen_pedido }}</span>
                        </v-col>

                        <v-col cols="12" md="7" class="pt-0 pl-0">
                            <v-list subheader color="transparent">
                                <v-list-item v-for="item in entregables" :key="item.id" >
                                    <v-list-item-content>
                                        {{ item.descripcion }} - {{ item.codigo }} - ${{ item.precio }}
                                        <!-- <v-list-item-title v-text="`${item.descripcion} - ${item.codigo}`"></v-list-item-title>
                                        <v-list-item-subtitle>$ {{ item.precio }}</v-list-item-subtitle> -->
                                    </v-list-item-content>
                                    
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-row>
                </v-card-text>
                
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="success" dark @click="marcarLeido" depressed>
                        <v-icon left>mdi-checkbox-marked-circle</v-icon>
                        <span>Leido</span>
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-card>
   
</template>
<script>
import moment from 'moment'
import platform from 'platform-detect'
import ApiService from '@/services/ApiService.js'
import SnackbarMensajes from '@/components/SnackbarMensajes.vue'

export default {
  name: 'Inbox',
  components: { SnackbarMensajes },
  data () {
    return {
        dialogMensaje: false,
        mensaje: {
            id: null,
            mensaje: null,
            idpedido: null,
            pedido: null
        },
        mensajes: [],
        dialogEspera: false,
        sb: {
            mostrar: false,
            mensaje: '',
            color: 'success',
            timeout: 3000,
            multiline: false,
        },
    }
  },
  computed:{
    noleidoscount() {
        if(this.mensajes.length > 0){
            return this.mensajes.reduce((acumulador, item) => {
                return acumulador + (!item.leido ? 1 : 0);
            }, 0);
        }else return 0;
    },
    entregables() {
        let arreglo = [];
        if(this.mensaje.id){
            this.mensaje.pedido.productos.forEach((item,idx,arr) => {
                if(item.nosurtir == 0 && item.cancelado == 0 && item.codigo.length > 0) arreglo.push(item);
            });
        }
        return arreglo;
    },
    resumen_pedido() {
        let str = '';
        if(this.mensaje.id){
            str = `${this.mensaje.pedido.cliente_nombre} ${this.mensaje.pedido.cliente_telefono}\n`;
            str = str + `${this.mensaje.pedido.direccion_calle} #${this.mensaje.pedido.direccion_numero} ${this.mensaje.pedido.direccion_entrecalles} COL. ${this.mensaje.pedido.direccion_colonia}`;
            str = str + (this.mensaje.pedido.observaciones ? `\n${this.mensaje.pedido.observaciones}` : '');
        }
        return str;
    },
  },
  methods: {
    marcarLeido() {
        this.dialogEspera = true;
        ApiService.marcarLeido(this.$store.getters.token,this.mensaje.id)
            .then(response => {
                // 201 => Created :: 200 => OK Updated
                //const checkStatus = this.editedItem.id == -1 ? 201 : 200;
                if(response.status == 200){
                  this.$store.dispatch('getMensajesNoLeidosCount');
                  this.getMensajes();
                }else this.showMensaje(`Error al marcar leido ${response.status} - ${response.statusText}`,'error',true);
            })
            .catch(error => {
                this.dialogEspera = false
                this.showMensaje(`Error al grabar ${error.response.status} - ${error.response.statusText}`,'error',true)
            })
            .finally(() => {
                this.dialogEspera = false
            })
        this.dialogMensaje = false;
    },
    itemSelected(item){
      this.mensaje = Object.assign({}, item);
      this.dialogMensaje = true;
    },
    showMensaje(mensaje,color,multiline) {
      this.sb.mensaje = mensaje
      this.sb.multiline = multiline
      this.sb.color = color
      this.sb.mostrar = true
    },
    getMensajes() {
        this.dialogEspera = true
        
        ApiService.getMensajes(this.$store.getters.token)
            .then(response => {
                if (response.status == 200) {
                    this.mensajes = response.data;
                } else {
                    this.showMensaje(
                        `Error al obtener listado ${response.status} - ${response.statusText} `,
                        'error', true)
                }
            })
            .catch(error => {
                if (error.response) this.showMensaje(
                    `Error al obtener listado ${error.response.status} - ${error.response.statusText}`,
                    'error', true)
                else this.showMensaje(`Error al obtener listado ${error.message}`, 'error', true)
                this.dialogEspera = false
            })
            .finally(() => (this.dialogEspera = false))
    },
  },
  mounted() {
      this.getMensajes();
  },
  created() {
      setInterval(() => {
          this.getMensajes();
      }, 300000);
  }
}
</script>
